<template>
  <div class="inspect">
    <div class="wrapper">
      <div class="title">
        <h2>Made with <span>❤</span> on top of following tech:</h2>
      </div>
      <div v-if="inspect.length" class="images">
        <img
          v-for="tech in inspect[0].techs"
          :key="tech.id"
          v-lazy="tech"
          alt="tech-logo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../firestore";
const inspectCollection = db.collection("inspect");

export default {
  name: "Inspect",
  components: {},
  data: function() {
    return {
      inspect: []
    };
  },
  mounted() {
    this.$bind("inspect", inspectCollection);
  }
};
</script>

<style lang="scss" scoped>
.inspect {
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: scroll;
}
span {
  color: var(--error);
}
.wrapper {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  margin-top: 60px;
  margin-bottom: 60px;
  h2 {
    text-decoration: underline 8px solid var(--color07);
  }
}
.images {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 30px;
  justify-items: center;
  align-items: center;
  padding-bottom: 30px;
  img {
    width: 200px;
    height: auto;
  }
}
@media (min-width: 769px) {
  .inspect {
    overflow-y: visible;
  }
  .images {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
